<template>
  <v-form
    :lazy-validation="true"
    @submit.prevent="handleSubmit"
    v-model="valid"
  >
    <v-card>
      <v-toolbar color="primary" dark flat>
        <v-toolbar-title>{{ $t("common.edit") }}</v-toolbar-title>
        <div class="flex-grow-1"></div>
      </v-toolbar>

      <v-card-text>
        <v-text-field
          :label="$t('company.fields.name')"
          name="name"
          prepend-icon="mdi-account-box-outline"
          type="text"
          v-model="userCompany.name"
          :rules="stringRules"
        ></v-text-field>
      </v-card-text>

      <v-card-text>
        <v-text-field
          :label="$t('company.fields.orgNumber')"
          name="orgNumber"
          prepend-icon="mdi-domain"
          type="text"
          v-model="userCompany.orgNumber"
        ></v-text-field>
      </v-card-text>

      <v-card-text>
        <v-text-field
          :label="$t('company.fields.postalAdress')"
          name="postalAdress"
          prepend-icon="mdi-home-city-outline"
          type="text"
          v-model="userCompany.postalAdress"
          :rules="stringRules"
        ></v-text-field>
      </v-card-text>

      <v-card-text>
        <v-text-field
          :label="$t('company.fields.deliveryAdress')"
          name="deliveryAdress"
          prepend-icon="mdi-home-city-outline"
          type="text"
          v-model="userCompany.deliveryAdress"
        ></v-text-field>
      </v-card-text>

      <v-card-text>
        <phone-number-input v-on:validation="changeValidState" v-model="phonenumber" />
      </v-card-text>

      <v-card-text>
        <v-text-field
          :label="$t('company.fields.invoiceAdress')"
          name="invoiceAdress"
          prepend-icon="mdi-at"
          type="text"
          v-model="userCompany.invoiceAdress"
          :rules="stringRules"
        ></v-text-field>
      </v-card-text>

      <v-card-text>
        <v-text-field
          :label="$t('company.fields.refrenceNmuber')"
          name="refrenceNmuber"
          prepend-icon="mdi-account-box-outline"
          type="text"
          v-model="userCompany.refrenceNmuber"
        ></v-text-field>
      </v-card-text>

      <v-card-text>
        <LangSwitch v-on:update-lang="updateCountry" />
      </v-card-text>

      <v-card-text>
        <v-file-input :label="$t('company.fields.image')" v-model="image" />
      </v-card-text>

      <v-card-text v-if="userCompany.logoImage">
        <v-img
          max-width="700"
          v-if="userCompany.logoImage && SO_API_BASE_URL != undefined"
          :src="SO_API_BASE_URL + '/image/' + userCompany.logoImage"
        />
        <v-btn color="error" type="btn" @click="removeImage">{{
          $t("tag.removeImage")
        }}</v-btn>
      </v-card-text>

      <v-card-actions>
        <v-btn color="primary" :disabled="!phoneNumberValid" type="submit" x-large>{{
          $t("common.save")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { mapActions, mapState } from "vuex";
import LangSwitch from "@/components/company/LangSwitch";

export default {
  name: "CompanyEdit",

  data() {
    return {
      phoneNumberValid: true,
      phonenumber: "",
      submitted: false,
      valid: false,
      image: null,
      nameRules: [
        (v) => !!v || this.$t("company.validation.nameRequired"),
        (v) => (v && v.length <= 255) || this.$t("company.validation.nameMax"),
      ],

      orgNumberRules: [
        (v) => !!v || this.$t("company.validation.orgNumberRequired"),
        (v) =>
          /([5]\d{5}[-]\d{4})|([5]\d{9}\s)/.test(v) ||
          this.$t("company.validation.orgNumberFormatError"),
      ],

      stringRules: [
        (v) => !!v || this.$t("validation.requiredSingle"),
        (v) => (v && v.length <= 255) || this.$t("validation.toLong"),
      ],
    };
  },

  computed: {
    ...mapState("configuration", ["SO_API_BASE_URL"]),
    ...mapState("users", [
      "status",
      "currentUser",
      "userCompany",
      "hasCompany",
    ]),
  },

  methods: {
    ...mapActions("companies", ["editCompany", "postImage", "deleteImage"]),
    ...mapActions("configuration", ["getBaseUrl"]),

    async handleSubmit() {
      this.submitted = true;
      this.userCompany.contactPhonenumber = this.phonenumber;

      await this.editCompany({
        company: this.userCompany,
      });

      if (this.image != null) {
        let formData = new FormData();
        formData.append("image", this.image);

        // Update the image
        await this.postImage({ image: formData });
      }

      this.$router.push("/company");
    },

    async removeImage() {
      this.deleteImage();
    },

    updateCountry(c) {
      this.userCompany.country = c;
    },

    changeValidState(val) {
      this.phoneNumberValid = val
    }
  },

  async created() {
    await this.getBaseUrl();
    this.phonenumber = this.userCompany.contactPhonenumber;
  },

  components: {
    LangSwitch,
  },
};
</script>

<style></style>
